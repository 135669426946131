.header {

  button, a {

    &:after {
      content: '';
      display: block;
      padding-bottom: 6px;
      border-bottom: solid 6px;
      transform: scaleX(0);
      transform-origin: 0;
      transition: transform 0.25s;
    }

    &:hover {
      &:after {
        transform: scaleX(1);
      }
    }

  }
}

.mobile-header {
  z-index: 1;
  position: absolute;
  top: 0;
  right: 0;

  button {
    padding: 0;
    margin-top: 20px;
    margin-right: 8px;
  }
}