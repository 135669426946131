.project-image-container {

  div {
    width: 100%;
    height: 100%;
  }

}

.project-content {

  .project-technologies {

    flex-wrap: wrap;

    .project-technology {
      border-radius: 4px;
      padding: 2px 6px 2px 6px;
      margin-right: 8px;
      margin-bottom: 8px;
    }

  }

  .source-button {
    padding: 10px;
  }

}




