.global-transition-color {

  transition: all 2s ease-in-out;
  transition-property: color, background-color;

  * {
    transition: all 2s ease-in-out;
    transition-property: color, background-color, stroke, fill, border-bottom-color, border-top-color, border-left-color, border-right-color;
  }
}

.no-transition {
  transition: none;
}

.icon-transition {
  polyline, path, polygon {
    transition: none;
  }
}

.visibility-hidden {
  visibility: hidden;
}

.display-none {
  display: none !important;
}

.italic-text {
  font-style: italic;
}