.layer {
  background-color: transparent !important;
}

.textAreaForm {
  div {
    height: 100%;
    width: 100%;

    textarea {
      height: 100%;
      width: 100%;
    }
  }
}